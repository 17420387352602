// 定义接口跟路径
export default {
    mock:{
        name: "虚拟环境接口",
        url: "http://example"
    },
    dev:{
        name: "开发环境接口",
        url: "http://139.198.181.238:8003"
    },
    pro:{
        name:"生成环境接口",
        url:"http://47.107.45.10:8003"
    },
    test:{
        name:"测试环境接口",
        // url:"http://stopup.top:39002"
        url: "http://139.198.181.238:8003"
    }
}